<template>
  <a-drawer width="65%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <!--      <a-form-model-item :label="$t('商品足迹.商品编号')" prop="goodsId" >-->
      <!--        <a-input v-model="form.goodsId" :placeholder="$t('通用.输入.请输入')+$t('商品足迹.商品编号')" />-->
      <!--      </a-form-model-item>
            <a-form-model-item :label="$t('商品足迹.用户id，外键{zb_user.id}')" prop="userId" >
              <a-input v-model="form.userId" :placeholder="$t('通用.输入.请输入')+$t('商品足迹.用户id，外键{zb_user.id}')" />
            </a-form-model-item>
            <a-form-model-item :label="$t('商品足迹.${comment}')" prop="searchNum" >
              <a-input v-model="form.searchNum" :placeholder="$t('通用.输入.请输入')+$t('商品足迹.${comment}')" />
            </a-form-model-item>
            <a-form-model-item :label="$t('商品足迹.排序')" prop="sort" >
              <a-input v-model="form.sort" :placeholder="$t('通用.输入.请输入')+$t('商品足迹.排序')" />
            </a-form-model-item>
            <a-form-model-item :label="$t('商品足迹.备注')" prop="remark" >
              <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')+内容" type="textarea" allow-clear />
            </a-form-model-item>-->
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl"/>
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.按钮.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <a-form-model-item :label="$t('通用.文本.选择商品')">
      <div @click="openProdSelect" style="width: 100px">
        <a-button type="dashed" @click="openProdSelect" danger>
          <span style="color: dodgerblue"> {{ $t('通用.文本.选择商品') }}</span>
        </a-button>
      </div>
    </a-form-model-item>
    <a-table v-if="this.form.goodsList.length>0"
             :loading="goodsLoading"
             :scroll="{ x: '130%' }"
             rowKey="id"
             :size="tableSize"
             :columns="goodsColumns"
             :data-source="form.goodsList"
             :pagination="false"
             :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      <template slot="picture" slot-scope="text, record">
        <div>
          <img v-if="record.picture" :src="record.picture" style="width:60px;height:62px;"
               @click="handleRecordPreview(record.picture)" alt="none"/>
        </div>
      </template>

      <span slot="categoryId" slot-scope="text, record">
            {{ getCategoryNameById(record.categoryId) }}
        </span>
      <span slot="brandId" slot-scope="text, record">
             {{ getBrandNameById(record.brandId) }}
          </span>
      <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>
          </span>
      <span slot="operation" slot-scope="text, record">
          <a @click="handleGoodsDelete(record.id)">
            <a-icon type="delete"/>{{ $t('通用.按钮.删除') }}
          </a>
        </span>
    </a-table>
    <checkbox-goods-select-form ref="CheckboxGoodsSelectForm"
                                @goodsSelect="indexGoodsModalSelect"></checkbox-goods-select-form>
  </a-drawer>
</template>

<script>
import {getBrowse, addBrowse, updateBrowse} from '@/api/goods/browse'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";
import CheckboxGoodsSelectForm from "@/views/goods/goods/modules/CheckboxSelectForm";
import {listCategory} from "@/api/goods/category";
import {allBrand} from "@/api/goods/brand";


export default {
  name: 'CreateForm',
  props: {},
  components: {
    CustomDictTag,
    CheckboxGoodsSelectForm,

  },
  data() {
    return {
      goodsLoading: false,
      goodsColumns: [
        {
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          width: 110,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 200,
          fixed: 'left',
        },
        {
          title: this.$t('商品.包装单位'),
          width: 60,
          dataIndex: 'unit',
        },
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },
        {
          title: this.$t('商品.售卖开始时间'),
          width: 100,
          dataIndex: 'saleStartTime',
        },
        {
          title: this.$t('商品.售卖结束时间'),
          width: 100,
          dataIndex: 'saleEndTime',
        },
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },
        {
          title: this.$t('商品.商品状态'),
          dataIndex: 'status',
          width: 60,
          scopedSlots: {customRender: 'status'},
        },
        {
          title: this.$t('商品.创建时间'),
          dataIndex: 'createTime',
          width: 100,
          scopedSlots: {customRender: 'createTime'},
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          fixed: 'right',
          width: 80,
          scopedSlots: {customRender: 'operation'},
        }
      ],
      categoryList: [{id: "0", categoryName: ''}],
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        goodsId: null,
        goodsList: [],

        userId: null,

        searchNum: null,

        sort: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        goodsId: [
          {required: true, message: this.$t('商品足迹.商品编号') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        userId: [
          {required: true, message: this.$t('商品足迹.用户id，外键{zb_user.id}') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ]
      }
    }
  },
  filters: {},
  created() {
    this.getBrandList();
    this.getCateList();
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    openProdSelect() {
      this.$refs.CheckboxGoodsSelectForm.openGoods(undefined, this.form.goodsList, 2);
    },
    indexGoodsModalSelect(records) {
      this.form.goodsList = records
      console.log('商品选择了', this.form.goodsList)
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    handleGoodsDelete(key) {
      const dataSource = [...this.form.goodsList];
      this.form.goodsList = dataSource.filter(item => item.id !== key);
    },


    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },

    getCateList() {
      listCategory().then(response => {
        this.categoryList = response.data;
        const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
        cate.children = this.handleTree(response.data, 'id','pid')
        this.cateOptions.push(cate)
      })
    },
    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        goodsId: null,
        goodsList: [],
        userId: null,
        searchNum: null,
        sort: null,
        createTime: null,
        remark: null
      }

    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBrowse({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateBrowse(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addBrowse(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
